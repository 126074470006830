@import '@ping/assets/scss/theme/variables';

.card-root {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 1rem;
  background: $color-gray-700;
  position: relative;
  isolation: isolate;
}
