@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.sidebar {
  display: none;

  @include media-breakpoint-up('lg') {
    // TODO: sync burger-menu appearance with sidebar
    display: flex;
    flex-direction: column;
    gap: 1rem;
    inline-size: $sidebar-inline-size;
    border-inline-end: 0.5px solid $color-gray-300;
    padding: 2rem 2.5rem 2.5rem 2.25rem;
    position: sticky;
    top: 0;
  }
}
