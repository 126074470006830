@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

/* --- Table --- */

.table {
  width: 100%;
  position: relative;

  thead {
    tr {
      box-shadow: 0 1px 0 $color-gray-400;
      background: $color-black;
      th {
        &:hover {
          &.headers-hover {
            path {
              fill: $color-white;
            }
            span {
              color: $color-white !important;
            }
          }
        }
        span {
          color: $color-gray-200;
          background: $color-black;
          transition: $transition-hover;
          /* 02 Body/regular */
          font-weight: 400;
        }
      }
    }
  }
  tbody tr {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $color-white;
    transition: $transition-hover;

    &[data-index='even'] {
      background: $color-gray-700;
    }
  }
  th,
  td {
    border: none !important;
    padding: 0 8px;
    height: $card-header-height;

    &:first-child {
      padding: 0 8px 0 16px;
    }
    &:last-child {
      padding: 0 16px 0 8px;
    }
  }
  &__no-data-icon {
    margin: auto;
    display: block;
    margin-block-start: 7.5rem;
    user-select: none; // Remove the big text cursor
  }
}

.header {
  thead {
    position: sticky;
    z-index: 1;
    width: fit-content;
  }
}

.sticky {
  &.table {
    display: block;
  }
  thead [data-sticky-td] {
    background-color: $color-black;
  }
  tbody {
    position: relative;
    z-index: 0;
  }
  tbody tr:nth-child(odd) {
    [data-sticky-td] {
      background-color: $color-gray-700;
    }
  }
  tbody tr:nth-child(even) {
    [data-sticky-td] {
      background-color: $color-black;
    }
  }
  th,
  td {
    display: flex !important;
    align-items: center;
  }
  td,
  td span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [data-sticky-td] {
    position: sticky;
  }
  [data-sticky-last-left-td] {
    border-right: 4px solid $color-gray-400 !important;
  }
  overflow-x: scroll;
}

.column-text-center {
  justify-content: center !important;
  text-align: center !important;
}
.column-text-start {
  justify-content: flex-start !important;
  text-align: start !important;
}
.column-text-end {
  justify-content: flex-end !important;
  text-align: end !important;
}

.observer-element {
  position: absolute;
  bottom: 200px;
  left: 0;
  td {
    height: 0;
  }
}

.spinner {
  display: block;
  text-align: center;
  margin: 15px 0;
}

/* --- Sorting Icon --- */

.sort-icon {
  path {
    fill: $color-gray-200;
    transition: $transition-hover;
  }
  margin-left: 8px;
  height: 19px;

  &[data-sorted-desc='true'] {
    transform: scale(0.75) rotate(270deg);
  }

  &[data-sorted-desc='false'] {
    transform: scale(0.75) rotate(90deg);
  }
}

/* --- Skeleton --- */

.skeleton {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: $color-gray-500;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background: linear-gradient(
        126deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(146, 138, 138, 0.15) 50%,
        rgba(255, 255, 255, 0) 70%
      )
      $color-gray-500;
    &:global {
      animation: 1s shine ease-in-out infinite;
    }
  }
}
