@import '@ping/assets/scss/theme/variables';

.sidebar-section {
  display: inherit;
  flex-direction: inherit;
  gap: inherit;

  & + & {
    margin-block-start: 1.5rem;
    padding-block-start: 1.5rem;
    border-block-start: 0.5px solid $color-gray-300;
  }
}
