@import '@ping/assets/scss/theme/variables';

.sign-success-modal {
  min-block-size: auto;
  max-width: 28rem;
  border: 1px solid $color-gray-400;
  border-radius: 1rem;

  [class*='modal-content'] {
    padding: 32px;
    padding-block-end: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__icon {
    color: $color-success;
    inline-size: 56px;
    block-size: 56px;
    margin-block-end: 1rem;
  }

  &__title {
    text-align: center;
    /* Headings/H4 */
    font-family: inherit;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: -0.2px;
  }

  &__description {
    color: $color-gray-100;
    margin-block-end: 0;
    text-align: center;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  &__actions {
    justify-content: center;
    padding-block-end: 32px;
    margin-block-start: 48px;
  }

  &__actions-button {
    background-color: $color-primary;
    width: 100%;
    padding: 14px 24px;
    text-transform: uppercase;
    display: flex;
    width: 272px;
    align-self: center;
    justify-content: center;
  }
}
