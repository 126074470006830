@import '@ping/assets/scss/theme/variables';

.divider {
  inline-size: 100%;
  block-size: 0.5px;
  background: $color-gray-300;

  &[data-vertically='true'] {
    inline-size: 0.5px;
    block-size: 100%;
  }
}
