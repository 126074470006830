@use '../../../../../../assets/scss/theme/variables' as *;

.sign-form-ltc {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  color: $color-white;
  background: $color-gray-700;

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--row {
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;

      > div {
        flex: 1;
      }
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    color: $color-white;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5;
    color: $color-gray-100;
    margin: 0;
  }

  &__emphasis {
    color: $color-white;
    font-weight: 600;
  }

  &__information {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    &-title {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: $color-white;
    }

    &-value {
      font-size: 1rem;
      line-height: 1.5;
      margin: 0;
      color: $color-gray-100;
      word-break: break-all;
    }
  }

  &__confirmations {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 0;
    padding: 1rem 0;

    .sign-form-ltc__information-value {
      color: $color-gray-100;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 2rem;
    margin-top: 1rem;

    .sign-form-ltc__information-title {
      font-size: 1rem;
      color: $color-white;
    }

    .sign-form-ltc__information-value {
      color: $color-gray-100;
    }

    button {
      min-width: 200px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
