@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.user-layout {
  display: flex;
  align-items: stretch;

  &__section {
    padding: 2rem 1rem;
    flex-grow: 1;
    min-width: 0;
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up('md') {
      padding: 2.5rem;
    }
  }
}

.second-sidebar {
  height: 100%;
}

.logout {
  color: $color-gray-100;
  background-color: $color-gray-600;
  margin-top: auto;

  &:hover {
    color: $color-gray-100;
    background-color: $color-gray-500;
  }
}
