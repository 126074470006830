@import '@ping/assets/scss/breakpoint';

.card-header {
  border-start-start-radius: 1rem;
  border-start-end-radius: 1rem;
  background: inherit !important;
  padding: 1rem 1rem 2rem;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @include media-breakpoint-up('md') {
    padding: 3rem 3rem 2.5rem;
    flex-direction: row;
  }
}
