@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.scroll-button {
  padding: 1.25rem 1.25rem calc(1.25rem + env(safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 2.1875rem;

  position: fixed;
  inset-inline: 0;
  inset-block-end: 0;

  background: linear-gradient(180deg, rgba($color-gray-700, 0.1) 0%, $color-gray-700 100%);
  transition: opacity 0.36s ease-in-out;

  &[data-scroll-target-in-view] {
    visibility: hidden;
    opacity: 0;
    z-index: -100;
  }

  @include media-breakpoint-up('lg') {
    position: fixed;
    left: $sidebar-inline-size;
    width: calc(100% - $sidebar-inline-size);
    padding: 0rem 3rem 3rem;
    gap: 0.5rem;
  }

  &__trigger {
    inline-size: 100%;
    padding: 0.75rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    text-transform: uppercase;
    border-radius: 0.1875rem;
    border: 1px solid $color-primary;
    background-color: $color-gray-700;

    /* 02 Body HC/semibold */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: 0.0175rem;
  }
}
