@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.action-tooltip {
  &__dropdown {
    @include media-breakpoint-down('md') {
      position: unset;
    }
  }
  &__toggle {
    &:hover {
      path {
        fill: $color-white;
      }
    }
  }
  &__toggle {
    path {
      transition: $transition-hover;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &__menu {
    background: $color-gray-500;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px 12px;
    position: relative;
    z-index: 5;

    &:before {
      position: absolute;
      content: '';
      width: 13px;
      height: 13px;
      transform: rotate(45deg);
      background: $color-gray-500;
      z-index: -1;
      right: 18px;
      padding: 0;
    }
    &[x-placement*='bottom'] {
      &:before {
        top: -6px;
      }
    }
    &[x-placement*='top'] {
      &:before {
        bottom: -6px;
      }
    }
    &.show {
      z-index: 1;
    }
  }
  &__item {
    padding: 8px 16px;
    width: 100%;
    text-align: left;
    transition: $transition-hover;
    display: flex;
    gap: 8px;

    &:hover {
      background: $color-gray-400;
      text-decoration: none;
    }
  }
  &__item-no-hover {
    &:hover {
      background: transparent;
      pointer-events: inherit;
      cursor: not-allowed;
    }
  }

  &__content {
    padding: 8px 16px 16px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid $color-gray-400;
    margin-bottom: 8px;
  }

  &__icon {
    color: $color-gray-100;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }

  &__disabled {
    color: $color-gray-300;
  }
}
