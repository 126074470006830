@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.sidebar-item {
  &[data-disabled='true'] {
    cursor: not-allowed;
  }

  &__link {
    padding-block: 0.5rem;
    padding-inline: 1.25rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: $color-gray-100;
    text-decoration: none;
    text-align: start;
    border-radius: 3px;
    user-select: none;
    user-drag: none;
    transition: $transition-hover;
    background-color: transparent;
    border: none;

    /* 01 Body/regular */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      text-decoration: none;
      color: $color-white;
    }

    &:focus-visible {
      outline: none;
      background-color: $color-primary-light;
      color: $color-white;
    }

    &[data-active='true'] {
      color: $color-white;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        inset-inline-start: 0;
        inline-size: 0.25rem;
        block-size: 1.75rem;
        background: $color-primary;
        border-radius: 2px;
      }
    }

    &[data-disabled='true'] {
      color: $color-gray-300;
      pointer-events: none;
    }
  }
}
