@import '@ping/assets/scss/theme/variables';

.checkbox-wrapper {
  .checkbox-label {
    margin: 0;
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    input {
      display: none;
      &:checked ~ .checkbox-mark {
        background: $color-primary;
        border-color: $color-primary;
        &::after {
          transform: translate(50%, -50%) scale(1);
          opacity: 1;
        }
      }
    }
    .checkbox-mark {
      display: inline-flex;
      min-width: 20px;
      min-height: 20px;
      background: transparent;
      border: 2px solid $color-gray-200;
      border-radius: 2px;
      position: relative;
      transition: all 200ms ease;
      &::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        border-radius: 2px;

        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) scale(0);
        background: url('/img/tick.svg') no-repeat center;
        background-size: contain;
        opacity: 0;
        transition: all 200ms ease;
      }
    }
    .checkbox-text {
      margin-inline-start: 12px;
      margin-top: -2px;
      color: $color-gray-100;
    }
  }
}
