@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.verifyOwnership {
  position: relative;
  height: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  &__notice {
    display: flex;
    border-radius: 16px;
    border: 1px solid $color-gray-500;
    background-color: $color-gray-600;
    padding: 20px;
    column-gap: 12px;

    &-icon {
      color: $color-warning;
      inline-size: 1.5rem;
      block-size: 1.5rem;
      min-width: 1.5rem;
      aspect-ratio: 1;
    }

    &-heading {
      color: $color-white;
      display: block;
      text-wrap: balance;
      margin-bottom: 8px;
    }

    &-link {
      text-align: right;
      color: $color-primary-light;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $color-primary-light;
      }
    }
  }
  &__button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
