@import '@ping/assets/scss/theme/variables';

.external-core-pass-address-verification-alert {
  max-width: 32rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;

  border-radius: 1rem;
  border: 1px solid $color-gray-400;
  background: $color-black;

  &__title {
    color: $color-white;
    text-wrap: balance;

    /* Headings/H4 */
    font-family: inherit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem; /* 160% */
    letter-spacing: -0.0125rem;
  }

  &__description {
    color: color-gray-100;
    text-wrap: balance;

    /* 02 Body/regular */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
  }
}
