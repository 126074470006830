@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.copyable {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s;

  &[aria-pressed='true'] {
    color: $color-success-medium;
  }
  svg {
    transition: $transition-hover;
  }
  &:hover {
    svg {
      color: $color-white;
    }
  }

  @include media-breakpoint-down('md') {
    width: 100% !important;
  }
}
