@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.sign-deceleration-modal {
  display: block;
  max-inline-size: 26rem;
  max-block-size: 32rem;
  inline-size: 100%;
  block-size: 100%;

  border-radius: 1rem;
  border: 1px solid $color-gray-400;

  &__content {
    padding-inline-start: 2rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }

  &__header {
    padding-block-start: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
  }

  &__contrast-outer {
    align-self: center;
    padding: 1.5rem;
    background: $color-gray-600;
    border: 1px solid $color-gray-500;
    border-radius: 1rem;
  }

  &__contrast-inner {
    padding: 1rem;
    background: $color-white;
    border-radius: 1rem;
    filter: drop-shadow(0px 12px 32px rgba(0, 0, 0, 0.25));
    inline-size: 18rem;
    block-size: 18rem;
  }

  &__qr-code {
    inline-size: 100%;
    block-size: 100%;
    max-inline-size: 16rem;
    max-block-size: 16rem;
  }
}

.corepass-modal {
  @include media-breakpoint-up('sm') {
    border-radius: 1rem;
    border: 1px solid $color-gray-400;
  }

  &__header {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    @include media-breakpoint-up('md') {
      padding: 2rem 1rem 0;
    }
  }

  &__close {
    padding: 0;
    margin-inline-start: auto;
  }

  &__content {
    align-self: stretch;
    padding: 0rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;

    @include media-breakpoint-up('md') {
      padding: 1rem 2rem 2rem;
    }
  }

  &__title {
    color: $color-white;

    /* Headings/H4 */
    font-family: inherit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    /* 160% */
    letter-spacing: -0.0125rem;
  }

  &__description {
    margin: 0;
    color: $color-gray-100;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */
  }

  &__link {
    color: $color-primary-light;
  }

  &__actions {
    padding-block-start: 1.25rem;
    padding-inline: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.4375rem;

    @include media-breakpoint-up('md') {
      padding: 2rem;
    }
  }

  &__redirect {
    appearance: none;
    border: 1px solid transparent;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    outline: none;
    user-select: none;
    padding: 0.875rem 1.5rem;
    width: 100%;

    color: $color-white;
    background-color: $color-primary;
    text-transform: uppercase;

    /* 02 Body HC/semibold */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    /* 142.857% */
    letter-spacing: 0.0175rem;

    &:hover,
    &:focus,
    &:active {
      color: $color-white;
      text-decoration: none;
    }
  }
}
