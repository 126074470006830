@import '@ping/assets/scss/theme/variables';

.compact {
  max-inline-size: 10rem;
  display: inline-block;
  white-space: nowrap;
  user-select: none;

  &__expanded {
    max-inline-size: 100%;
    overflow-wrap: break-word;
    white-space: unset;
    user-select: unset;
    cursor: pointer;
  }

  &[data-end='true'] {
    text-overflow: ellipsis;
  }
}
