@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.card-divider {
  inline-size: 100%;
  padding-inline: 1rem;

  [class*='divider'] {
    background: $color-gray-400;
  }

  @include media-breakpoint-up('md') {
    padding-inline: 3rem;
  }
}
