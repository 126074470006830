@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.link-external-wallet-address {
  position: relative;
  block-size: 100%;

  &__title {
    margin-block-end: 1rem;
    color: $color-white;

    /* Headings/H4 */
    font-family: inherit;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: -0.2px;
  }

  &__description {
    margin-block-end: 2rem;

    color: $color-gray-100;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  &__box {
    padding: 48px 24px 64px 24px;
    inline-size: 482px;
    block-size: 496px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    border-radius: 16px;
    border: 1px solid $color-gray-400;
    background: $color-gray-600;
  }

  &__refresh {
    display: flex;
    align-items: center;
  }

  &__refresh-text {
    color: $color-gray-100;

    /* 02 Body/regular */
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  &__refresh-timer {
    color: $color-white;

    /* 02 Body HC/semibold */
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
  }

  &__space {
    position: relative;
    background: $color-white;
    border-radius: 16px;
    inline-size: 344px;
    block-size: 344px;
    flex-shrink: 0;
    padding: 20px;
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
