@import '@ping/assets/scss/theme/variables';

.user-layout-description {
  /* 01 Body/regular */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: $color-gray-100;
  display: block;
  max-inline-size: min(46.25rem, 100%);
  margin: 0; // reset default p margin
  margin-block-start: 1.5rem;
}
