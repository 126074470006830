@import '@ping/assets/scss/breakpoint';

.card-body {
  align-self: stretch;
  display: flex;
  padding: 1.5rem 1rem 1rem;
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-up('md') {
    padding: 2.5rem 3rem 3rem;
    flex: 1;
    overflow: auto;
  }
}
