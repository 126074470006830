@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.card-title {
  margin: 0; // reset browser style
  color: $color-white;

  /* Headlines/H3 */
  font-family: inherit;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem; /* 133.333% */
  letter-spacing: -0.015rem;

  @include media-breakpoint-up('xxl') {
    /* Headlines/H2 */
    font-family: inherit;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem; /* 125% */
    letter-spacing: -0.02rem;
  }
}
