@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.card-leading-icon {
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1;
  border-radius: 50%;

  border: 1px solid $color-primary;
  color: $color-white;

  display: grid;
  place-content: center;
}
